import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import './Table.css';

const Table = ({ filters }) => {
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    const fetchStateData = async () => {
      try {
        const response = await fetch('/project_data.json');
        if (!response.ok) throw new Error('Failed to load project data');
        const data = await response.json();

        // Filter and aggregate data
        const aggregatedData = data.reduce((acc, item) => {
          if (
            (!filters.project || item.PROJECT_ID === filters.project) &&
            (!filters.client || item.Project_Name === filters.client) &&
            (!filters.projectType || item.Project_Type === filters.projectType) &&
            (!filters.serviceType || item["Type(Sch/Doc)"] === filters.serviceType) &&
            (!filters.scheme || item.scheme_name === filters.scheme)
          ) {
            const state = item.st_nm;
            if (!acc[state]) {
              acc[state] = {
                applicationOpen: 0,
                applicationsSubmitted: 0,
                bR: 0,
                benefitsUnlocked: 0,
                // Directly take values for these fields without aggregation
                citizensImpacted: item.Citizen_impacted || 0,
                msmesImpacted: item['Number_of_total_msme_reached/screened'] || 0,
                hdsTrained: item.Number_of_Haqdarshaks_Trained || 0,
              };
            }

            acc[state].applicationOpen += item.OpenCount || 0;
            acc[state].applicationsSubmitted += item.submitted || 0;
            acc[state].bR += item.BR || 0;
            acc[state].benefitsUnlocked += item.BV || 0;
          }
          return acc;
        }, {});

        setStateData(Object.entries(aggregatedData).map(([state, data]) => ({ state, ...data })));
      } catch (error) {
        console.error('Error loading the state data:', error);
      }
    };

    fetchStateData();
  }, [filters]);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(stateData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'State Data');
    XLSX.writeFile(workbook, 'state_data.xlsx');
  };

  return (
    <div className="table-container">
      <button onClick={exportToExcel} className="export-btn">Export to Excel</button>
      <table>
        <thead>
          <tr>
            <th>State</th>
            <th>Applications Opened</th>
            <th>Applications Submitted</th>
            <th>Benefits Received</th>
            <th>Citizens Impacted</th>
            <th>MSMEs Reached</th>
            <th>HDs Trained</th>
            <th>Benefits Unlocked</th>
          </tr>
        </thead>
        <tbody>
          {stateData.map((data, index) => (
            <tr key={index}>
              <td>{data.state}</td>
              <td>{data.applicationOpen.toLocaleString()}</td>
              <td>{data.applicationsSubmitted.toLocaleString()}</td>
              <td>{data.bR.toLocaleString()}</td>
              <td>{data.citizensImpacted.toLocaleString()}</td>
              <td>{data.msmesImpacted.toLocaleString()}</td>
              <td>{data.hdsTrained.toLocaleString()}</td>
              <td>{data.benefitsUnlocked.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
