import React, { useState, useEffect } from 'react';
import Map from './Map';
import './AllFilterDashboard.css';
import RightSidebar from './AllFilterRightSidebar';
import { ButtonContainer } from './Button';
import AllFilterSidebar from './AllFilterSidebar';
import Cards from '../components/Cards'; // Import Cards component
import Table from './Table'; // Import the Table component

const AllFilterDashboard = () => {
  const [filters, setFilters] = useState({
    client: '',
    project: '',
    projectType: '',
    serviceType: '',
    scheme: ''
  });

  const [topSchemes, setTopSchemes] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null); // State for last updated time

  // Fetch last updated time from a JSON file
  useEffect(() => {
    const fetchLastUpdated = async () => {
      try {
        const response = await fetch('/date&time.json');
        const data = await response.json();
        setLastUpdated(new Date(data.updatedAt).toLocaleString()); // Convert to readable format
      } catch (error) {
        console.error('Error fetching last updated time:', error);
      }
    };

    fetchLastUpdated();
  }, []);

  // Define the handleFilterChange function
  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  // Apply filters to topSchemes based on the selected scheme
  const filteredSchemes = topSchemes.filter((scheme) => {
    if (filters.scheme && filters.scheme !== scheme.scheme_name) {
      return false;
    }
    return true;
  });

  return (
    <div className="main-content">
      {/* Place Cards component outside of the main AllFilterDashboard layout */}
      <Cards />
      <div className="allfilterdashboard">
        <AllFilterSidebar setFilters={handleFilterChange} setTopSchemes={setTopSchemes} />
        <div className="map-container">
          <ButtonContainer />
          <Map filters={filters} />
        </div>
        <div className="right-sidebar">
          
          {/* Last updated section below RightSidebar */}
          {lastUpdated && (
            <div className="last-updated">
              <p>Last updated: {lastUpdated}</p>
            </div>
          )}
          <RightSidebar topSchemes={filteredSchemes} />
        </div>
      </div>
      <Table filters={filters} />
    </div>
  );
};

export default AllFilterDashboard;
