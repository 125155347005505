// src/components/CitizenImpactButton.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './CitizenImpactButton.css';

const CitizenImpactButton = ({ label, path, className, isActive, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick(); // Update active button state
    navigate(path); // Navigate to the route
  };

  return (
    <button
      className={`citizen-impact-dashboard-button ${className} ${isActive ? 'active' : ''}`}
      onClick={handleClick}
    >
      {label}
    </button>
  );
};

const CitizenImpactButtonContainer = () => {
  const location = useLocation(); // Get the current path from the router
  const [activeButton, setActiveButton] = useState('');

  const buttons = [
    { label: 'Explore by Project attributes', path: '/', className: 'project-impact-citizen-button' },
    { label: 'Explore by HD attributes', path: '/hd-impact', className: 'hd-impact-citizen-button' },
    { label: 'Explore by Citizen attributes', path: '/citizen-impact', className: 'citizen-impact-button' },
  ];

  // Automatically set the active button based on the current path
  useEffect(() => {
    const currentPath = location.pathname;
    const activeButtonLabel = buttons.find((button) => button.path === currentPath)?.label || 'Project Impact';
    setActiveButton(activeButtonLabel);
  }, [location.pathname]); // Re-run when the path changes

  return (
    <div className="citizen-impact-button-container">
      {buttons.map((button) => (
        <CitizenImpactButton
          key={button.label}
          label={button.label}
          path={button.path}
          className={button.className}
          isActive={activeButton === button.label} // Check if the button is active
          onClick={() => setActiveButton(button.label)} // Update the active button on click
        />
      ))}
    </div>
  );
};

export { CitizenImpactButton, CitizenImpactButtonContainer };
