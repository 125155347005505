import React from 'react';
import './ClientRightSidebar.css';

const ClientRightSidebar = ({ schemes }) => {
  return (
    <div className="client-rightsidebar">
      <h3>Top Schemes Delivered</h3>
      <p className="client-location">All India</p>
      <div className="client-content">
        {schemes.map((scheme, index) => (
          <div key={index} className="client-card">
            <h4>{scheme.scheme_name}</h4>
            <p className="client-category">{scheme.Project_Name}</p>
            <div className="client-info">
              <span className="client-label">Benefit Received : </span>
              <span>{scheme.BR}</span>
            </div>
            <div className="client-info">
              <span className="client-label">Application Status : </span>
              <span>{scheme.Project_Status}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientRightSidebar;
