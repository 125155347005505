import React from 'react';
import './HistRightSidebar.css';

const HistRightSidebar = () => {
  return (
    <div className="histrightsidebar">
      <div className="histheader">
        <div className="header-icon">
          <img src={`${process.env.PUBLIC_URL}/Folder Briefcase.jpg`} alt="House Icon" />
        </div>
        <div className="header-text">
          <h2>Tata Sampan</h2>
        </div>
        <button className="header-arrow-btn">
        <img src="/Arrow Right.jpg" alt="Arrow Icon" />
        </button>
      </div>
      <h2>PM Krishi Yojana</h2>
      <p className="category">Agriculture</p>
      <div className="details">
        <div className="detail-item">
          <img src={`${process.env.PUBLIC_URL}/Money.jpg`} alt="Money Icon" className="icon" /> 10 Lakh
        </div>
        <div className="detail-item">
          <img src={`${process.env.PUBLIC_URL}/Hourglass Three Quarter.jpg`} alt="Time Icon" className="icon" /> 20-25 days
        </div>
      </div>
      <div className="stats">
        <div className="stat-item">
          <div className="stat-label">
            <img src={`${process.env.PUBLIC_URL}/Citizen_Impacted.jpg`} alt="People Icon" className="icon" /> Citizens Impacted
          </div>
          <div className="stat-value">64_lakh</div>
        </div>
        <div className="stat-item">
          <div className="stat-label">
            <img src={`${process.env.PUBLIC_URL}/Application_Submitted.jpg`} alt="Form Icon" className="icon" /> Applications Submitted
          </div>
          <div className="stat-value">62_lakh</div>
        </div>
        <div className="stat-item">
          <div className="stat-label">
            <img src={`${process.env.PUBLIC_URL}/MSMEs_Impacted.jpg`} alt="Briefcase Icon" className="icon" /> MSMEs Impacted
          </div>
          <div className="stat-value">69491</div>
        </div>
        <div className="stat-item">
          <div className="stat-label">
            <img src={`${process.env.PUBLIC_URL}/HDs_Trained.jpg`} alt="Education Icon" className="icon" /> HDs Trained
          </div>
          <div className="stat-value">39842</div>
        </div>
        <div className="stat-item">
          <div className="stat-label">
            <img src={`${process.env.PUBLIC_URL}/Benefits_Unblocked.jpg`} alt="Money Bag Icon" className="icon" /> Benefits Unlocked
          </div>
          <div className="stat-value">18600_cr</div>
        </div>
      </div>
      <div className="eligible-states">
        <h4>Eligible States</h4>
        <div className="states">
          <span className="state">Uttar Pradesh</span>
          <span className="state">Bihar</span>
          <span className="state">Karnataka</span>
          <span className="state">Arunachal Pradesh</span>
          <span className="state">Tamil Nadu</span>
          <span className="state">Madhya Pradesh</span>
          <span className="state">Gujarat</span>
          <span className="state">Tripura</span>
        </div>
      </div>
    </div>
  );
}

export default HistRightSidebar;
