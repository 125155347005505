import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Button.css';

const Button = ({ label, route, className, isActive, onClick }) => {
  const navigate = useNavigate();
  
  return (
    <button
      className={`dashboard-button ${className} ${isActive ? 'active' : ''}`}
      onClick={() => {
        onClick();  // Set the active button
        navigate(route); // Navigate to the route
      }}
    >
      {label}
    </button>
  );
};

const ButtonContainer = () => {
  // Set 'Explore by Project attributes' as the default active button
  const [activeButton, setActiveButton] = useState('Explore by Project attributes');

  const buttons = [
    { label: 'Explore by Project attributes', route: '/', className: 'project-impact-button' },
    { label: 'Explore by HD attributes', route: '/hd-impact', className: 'hd-impact-button' },
    { label: 'Explore by Citizen attributes', route: '/citizen-impact', className: 'citizen-impact-button' },
  ];

  return (
    <div className="button-container">
      {buttons.map((button) => (
        <Button
          key={button.label}
          label={button.label}
          route={button.route}
          className={button.className}
          isActive={activeButton === button.label}
          onClick={() => setActiveButton(button.label)} // Update active button on click
        />
      ))}
    </div>
  );
};

export { Button, ButtonContainer };
