import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Cards.css';

const OutlineCard = ({ logoSrc, title, value, bgColor }) => {
  return (
    <div className="outline-card" style={{ backgroundColor: bgColor }}>
      <div className="card-header">
        <img src={logoSrc} alt="Logo" className="logo" />
        <h3>{title}</h3>
      </div>
      <div className="card-content">
        <p><strong>{value}</strong></p>
      </div>
    </div>
  );
};

const formatNumber = (num) => {
  return new Intl.NumberFormat().format(num);
};

const Cards = () => {
  const [cardValues, setCardValues] = useState({
    applications_submitted: '',
    citizen_impacted: '',
    msmes_impacted: '',
    hds_trained: '',
    benefits_unblocked: ''
  });

  const fetchData = async () => {
    try {
      const response = await axios.get('/data.json');
      console.log('Fetched data:', response.data);

      setCardValues({
        applications_submitted: formatNumber(response.data.applications_submitted) || 0,
        citizen_impacted: formatNumber(response.data.citizen_impacted) || 0,
        msmes_impacted: formatNumber(response.data.msmes_impacted) || 0,
        hds_trained: formatNumber(response.data.hds_trained) || 0,
        benefits_unblocked: formatNumber(response.data.benefits_unblocked) || 0
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="card-container">
        <OutlineCard
          logoSrc="/Citizen_Impacted.jpg"
          title="Citizen Impacted"
          value={cardValues.citizen_impacted}
          bgColor="var(--citizen-impacted-color)"
        />
        <OutlineCard
          logoSrc="/Application_Submitted.jpg"
          title="Applications Submitted"
          value={cardValues.applications_submitted}
          bgColor="var(--applications-submitted-color)"
        />
        <OutlineCard
          logoSrc="/MSMEs_Impacted.jpg"
          title="MSMEs Reached"
          value={cardValues.msmes_impacted}
          bgColor="var(--msmes-impacted-color)"
        />
        <OutlineCard
          logoSrc="/HDs_Trained.jpg"
          title="HDs Trained"
          value={cardValues.hds_trained}
          bgColor="var(--hds-trained-color)"
        />
        <OutlineCard
          logoSrc="/Benefits_Unblocked.jpg"
          title="Benefits Unlocked"
          value={cardValues.benefits_unblocked}
          bgColor="var(--benefits-unblocked-color)"
        />
      </div>
    </div>
  );
};

export default Cards;
