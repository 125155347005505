import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './MsmeImpactMap.css';
import L from 'leaflet';

const MsmeImpactMap = ({ selectedGender, selectedAgeRange }) => {
  const [geoData, setGeoData] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const mapRef = useRef(null);

  useEffect(() => {
    // Fetch the GeoJSON data for the map
    fetch('/india.json')
      .then(response => response.json())
      .then(data => {
        setGeoData(data);
        if (mapRef.current) {
          const map = mapRef.current;
          const bounds = L.geoJSON(data).getBounds();
          map.fitBounds(bounds);
        }
      })
      .catch(error => console.error('Error loading the GeoJSON data:', error));

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('/msmeimpact.json'); // Fetch MSME impact data
        if (!response.ok) throw new Error('Failed to load MSME impact data');
        const data = await response.json();

        // Aggregate data based on selected gender and age range
        const aggregatedData = data.reduce((acc, curr) => {
          if (!curr['st_nm'] || !curr['Gender']) return acc;

          const genderMatch = !selectedGender || curr['Gender'].toLowerCase() === selectedGender.toLowerCase();
          const ageRangeMatch = !selectedAgeRange || curr['Age Range'] === selectedAgeRange;

          if (genderMatch && ageRangeMatch) {
            const stateName = curr['st_nm'] ? curr['st_nm'].trim().toLowerCase() : 'unknown';
            const citizenCount = curr['Total Citizens'] || 0; // Total MSMEs count
            const gender = curr['Gender'].toLowerCase();

            if (!acc[stateName]) {
              acc[stateName] = {
                state: curr['st_nm'].trim() || 'unknown',
                maleCount: 0,
                femaleCount: 0,
                othersCount: 0,
                totalCitizenCount: 0, // Total MSMEs count
              };
            }

            // Aggregate counts based on gender
            if (gender === 'male') {
              acc[stateName].maleCount += citizenCount;
            } else if (gender === 'female') {
              acc[stateName].femaleCount += citizenCount;
            } else {
              acc[stateName].othersCount += citizenCount;
            }

            acc[stateName].totalCitizenCount += citizenCount; // Update total MSMEs count
          }

          return acc;
        }, {});

        // Log the filtered aggregated data to the console
        console.log("Filtered Data:", Object.values(aggregatedData));

        setStateData(Object.values(aggregatedData));
      } catch (error) {
        console.error('Error loading state data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedGender, selectedAgeRange]); // Dependency array updated

  // Function to determine color based on MSME count
  const getColor = (value) => {
    return value > 8000  ? '#4C2C77' :
       value > 7000  ? '#5A3A8E' :
       value > 6000  ? '#6A48A6' :
       value > 5000  ? '#7B56BF' :
       value > 4000  ? '#8C64D8' :
       value > 3000  ? '#9E73E1' :
       value > 2000  ? '#B083EA' :
       value > 1500  ? '#C194F3' :
       value > 1000  ? '#D3A5FC' :
       value > 750   ? '#E4B6FF' :
       value > 500   ? '#F0C9FF' :
       value > 250   ? '#F7D6FF' :
       value > 100   ? '#FBE3FF' :
       value > 50    ? '#FDF0FF' :
       value > 0     ? '#FDF7FF' :
                       '#F0E6FF';
  };


  // Function to style each state based on aggregated data
  const style = (feature) => {
    const stateName = feature.properties.st_nm.toLowerCase();
    const state = stateData.find(s => s.state.toLowerCase() === stateName);
    const totalCount = state ? (state.maleCount + state.femaleCount + state.othersCount) : 0;

    const defaultColor = '#cccccc';

    return {
      fillColor: state ? getColor(totalCount) : defaultColor,
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7,
      interactive: true,
    };
  };

  // Function to handle popup content for each state
  const onEachFeature = (feature, layer) => {
    const stateName = feature.properties.st_nm.toLowerCase();
    const state = stateData.find(s => s.state.toLowerCase() === stateName);

    if (state) {
      const { maleCount, femaleCount, othersCount, totalCitizenCount } = state; // Use total MSMEs count
      const totalCount = maleCount + femaleCount + othersCount;

      let popupContent = `
      <div class="popup-header">
        <b>${feature.properties.st_nm.toUpperCase()}</b>
      </div>
      <div class="popup-body">
        <div class="popup-row">
          <span class="popup-label">Males:</span>
          <span class="popup-value">${maleCount.toLocaleString()}</span>
        </div>
        <div class="popup-row">
          <span class="popup-label">Females:</span>
          <span class="popup-value">${femaleCount.toLocaleString()}</span>
        </div>
        <div class="popup-row">
          <span class="popup-label">Others:</span>
          <span class="popup-value">${othersCount.toLocaleString()}</span>
        </div>
        
        <div class="popup-row popup-total">
          <span class="popup-label">Total:</span>
          <span class="popup-value">${totalCount.toLocaleString()}</span>
        </div>
      </div>
    `;
    
    // Rest of the code remains unchanged
    layer.bindPopup(popupContent);
    
    // Add hover and click events for the popups
    layer.on({
      click: function () {
        layer.openPopup();
      }
    });
    
    }
  };

  return (
    <div className="msme-impact-map">
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <MapContainer
          center={[22.9734, 82.6]}
          zoom={5}
          style={{ height: '100%', width: '100%' }}
          scrollWheelZoom={false}
          doubleClickZoom={false}
          zoomControl={false}
          whenCreated={(mapInstance) => {
            mapRef.current = mapInstance;
            mapInstance.setView([22.9734, 82.6], 4.5);
          }}
        >
          {geoData && (
            <GeoJSON
              key={`${selectedGender}-${selectedAgeRange}`} // Triggers re-render on filter change
              data={geoData}
              style={style}
              onEachFeature={onEachFeature}
            />
          )}
        </MapContainer>
      )}
    </div>
  );
};

export default MsmeImpactMap;
