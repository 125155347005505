import React, { useState, useEffect } from 'react';
import HDImpactCards from './HDImpactCards';
import './HDImpactDashboard.css';
import HDImpactSidebar from './HDImpactSidebar';
import HDImpactMap from './HDImpactMap';
import HDImpactRightSidebar from './HDImpactRightSidebar';
import { HDImpactButtonContainer } from './HDImpactButton';
import HDImpactMapTable from './HDImpactMapTable';

const HDImpactDashboard = () => {
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedAgeRange, setSelectedAgeRange] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null); // State for last updated time

  // Fetch last updated time from a JSON file
  useEffect(() => {
    const fetchLastUpdated = async () => {
      try {
        const response = await fetch('/date&time.json');
        const data = await response.json();
        setLastUpdated(new Date(data.updatedAt).toLocaleString()); // Convert to readable format
      } catch (error) {
        console.error('Error fetching last updated time:', error);
      }
    };

    fetchLastUpdated();
  }, []);

  const handleGenderChange = (gender) => {
    setSelectedGender(gender);
  };

  const handleAgeRangeChange = (ageRange) => {
    setSelectedAgeRange(ageRange);
  };

  const handleProjectNameChange = (projectName) => {
    setSelectedProjectName(projectName);
  };

  return (
    <div className="hdimpact-main-content">
      <HDImpactCards />
      <div className="hdimpact-dashboard">
        <HDImpactSidebar 
          onGenderChange={handleGenderChange} 
          onAgeRangeChange={handleAgeRangeChange} 
          onProjectNameChange={handleProjectNameChange} 
        />
        <div className="hdimpact-map-container">
          <HDImpactButtonContainer />
          <HDImpactMap 
            selectedGender={selectedGender} 
            selectedAgeRange={selectedAgeRange} 
            selectedProjectName={selectedProjectName} 
          />
        </div>
        <div className="hdimpact-right-sidebar">
         
          {/* Display last updated time below HDImpactRightSidebar */}
          {lastUpdated && (
            <div className="last-updated">
              <p>Last updated: {lastUpdated}</p>
            </div>
          )}
           <HDImpactRightSidebar />
        </div>
      </div>
      <HDImpactMapTable 
        selectedGender={selectedGender} 
        selectedAgeRange={selectedAgeRange} 
        selectedProjectName={selectedProjectName} 
      />
    </div>
  );
};

export default HDImpactDashboard;
