import React from 'react';
import './SchemeRightSidebar.css';

const SchemeRightSidebar = ({ projects }) => {
  return (
    <div className="scheme-right-sidebar">
      <h3>Top Projects Delivered</h3>
      <p className="scheme-location"> {/* Location or any other relevant info */} </p>
      <div className="scheme-content">
        {projects.map((project, index) => (
          <div key={index} className="scheme-card">
            <h4>{project.Project_Name}</h4>
            <p className="scheme-category">{project.scheme_name}</p>
            <div className="scheme-info">
              <span className="scheme-label">Id :   </span>
              <span>{project.PROJECT_ID}</span>
            </div>
            <div className="scheme-info">
              <span className="scheme-label">Status : </span>
              <span>{project.Project_Status}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SchemeRightSidebar;
