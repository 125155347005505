import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import './HDImpactRightSidebar.css';
import Chart from 'chart.js/auto';

const HDImpactRightSidebar = () => {
  const [genderData, setGenderData] = useState(null); // Initialize to null
  const [ageData, setAgeData] = useState(null); // Initialize to null

  // Fetch data from hdimpact.json
  useEffect(() => {
    fetch('/hdimpact.json')
      .then((response) => response.json())
      .then((data) => {
        // Aggregate the gender data across all entries
        const genderCount = data.reduce(
          (acc, item) => {
            if (item['HD Gender'] === 'Male') acc.Male += item['Distinct User Count'] || 0;
            else if (item['HD Gender'] === 'Female') acc.Female += item['Distinct User Count'] || 0;
            else if (item['HD Gender'] === 'OTHERS') acc.Others += item['Distinct User Count'] || 0;
            return acc;
          },
          { Male: 0, Female: 0, Others: 0 }
        );

        // Aggregate the age range data across all entries
        const ageCount = data.reduce(
          (acc, item) => {
            if (item['Age Range'] === 'Under 25') acc['Under 25'] += item['Distinct User Count'] || 0;
            else if (item['Age Range'] === '26-35') acc['26-35'] += item['Distinct User Count'] || 0;
            else if (item['Age Range'] === '36-45') acc['36-45'] += item['Distinct User Count'] || 0;
            else if (item['Age Range'] === '46-60') acc['46-60'] += item['Distinct User Count'] || 0;
            else if (item['Age Range'] === '60+') acc['60+'] += item['Distinct User Count'] || 0;
            return acc;
          },
          { 'Under 25': 0, '26-35': 0, '36-45': 0, '46-60': 0, '60+': 0 }
        );

        // Set the gender data for the pie chart
        setGenderData({
          labels: ['Male', 'Female', 'Others'],
          datasets: [
            {
              data: [genderCount.Male, genderCount.Female, genderCount.Others],
              backgroundColor: ['#f48fb1', '#ce93d8', '#ba68c8'], // Pink and violet shades
            },
          ],
        });

        // Set the age range data for the pie chart
        setAgeData({
          labels: ['Under 25', '26-35', '36-45', '46-60', '60+'],
          datasets: [
            {
              data: [
                ageCount['Under 25'],
                ageCount['26-35'],
                ageCount['36-45'],
                ageCount['46-60'],
                ageCount['60+'],
              ],
              backgroundColor: ['#f8bbd0', '#f48fb1', '#ce93d8', '#ba68c8', '#ab47bc', '#8e24aa'], // Pink to violet gradient
            },
          ],
        });
      })
      .catch((error) => console.error('Error fetching the data:', error));
  }, []);

  return (
    <div className="hdimpact-rightsidebar">
      <div className="hdimpact-content">
        <h4>Gender Distribution</h4>
        {genderData ? <Pie data={genderData} /> : <p>Loading...</p>}

        <h4>Age Range Distribution</h4>
        {ageData ? <Pie data={ageData} /> : <p>Loading...</p>}
      </div>
    </div>
  );
};

export default HDImpactRightSidebar;
