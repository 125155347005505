import React, { useEffect, useState } from 'react';
import './HDImpactSidebar.css';
import HDImpactDropdown from './HDImpactDropdown';

const HDImpactSidebar = ({ onGenderChange, onAgeRangeChange, onProjectNameChange }) => {
  const [allData, setAllData] = useState([]); // Store the raw data
  const [genderOptions, setGenderOptions] = useState([]);
  const [ageRangeOptions, setAgeRangeOptions] = useState([]);
  const [projectNameOptions, setProjectNameOptions] = useState([]);

  const [selectedGender, setSelectedGender] = useState('');
  const [selectedAgeRange, setSelectedAgeRange] = useState('');
  const [selectedProjectName, setSelectedProjectName] = useState('');

  // Fetch data from hdimpact.json
  useEffect(() => {
    fetch('/hdimpact.json')
      .then((response) => response.json())
      .then((data) => {
        setAllData(data); // Store raw data

        // Extract unique values
        const genders = [...new Set(data.map((item) => item["HD Gender"]))].filter((g) => g);
        setGenderOptions(genders);

        const ageRanges = [...new Set(data.map((item) => item["Age Range"]))].filter((a) => a);
        setAgeRangeOptions(ageRanges);

        const projectNames = [...new Set(data.map((item) => item["Project Name"]))].filter((p) => p);
        setProjectNameOptions(projectNames);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Filter options based on selected criteria
  useEffect(() => {
    if (selectedGender || selectedAgeRange || selectedProjectName) {
      const filteredData = allData.filter((item) => {
        return (
          (!selectedGender || item["HD Gender"] === selectedGender) &&
          (!selectedAgeRange || item["Age Range"] === selectedAgeRange) &&
          (!selectedProjectName || item["Project Name"] === selectedProjectName)
        );
      });

      // Update the options dynamically based on filtered data
      setGenderOptions([...new Set(filteredData.map((item) => item["HD Gender"]))].filter((g) => g));
      setAgeRangeOptions([...new Set(filteredData.map((item) => item["Age Range"]))].filter((a) => a));
      setProjectNameOptions([...new Set(filteredData.map((item) => item["Project Name"]))].filter((p) => p));
    }
  }, [selectedGender, selectedAgeRange, selectedProjectName, allData]);

  // Handle filter selection and resetting
  const handleGenderSelection = (selectedGender) => {
    setSelectedGender(selectedGender);
    onGenderChange(selectedGender);
  };

  const handleAgeRangeSelection = (selectedAgeRange) => {
    setSelectedAgeRange(selectedAgeRange);
    onAgeRangeChange(selectedAgeRange);
  };

  const handleProjectNameSelection = (selectedProjectName) => {
    setSelectedProjectName(selectedProjectName);
    onProjectNameChange(selectedProjectName);
  };

  // Clear individual filters and reset the corresponding dropdown
  const clearSelection = (type) => {
    if (type === 'gender') {
      setSelectedGender('');
      setGenderOptions([...new Set(allData.map((item) => item["HD Gender"]))].filter((g) => g));
    }
    if (type === 'ageRange') {
      setSelectedAgeRange('');
      setAgeRangeOptions([...new Set(allData.map((item) => item["Age Range"]))].filter((a) => a));
    }
    if (type === 'projectName') {
      setSelectedProjectName('');
      setProjectNameOptions([...new Set(allData.map((item) => item["Project Name"]))].filter((p) => p));
    }
  };

  // Clear all filters
  const handleClearAllFilters = () => {
    setSelectedGender('');
    setSelectedAgeRange('');
    setSelectedProjectName('');

    setGenderOptions([...new Set(allData.map((item) => item["HD Gender"]))].filter((g) => g));
    setAgeRangeOptions([...new Set(allData.map((item) => item["Age Range"]))].filter((a) => a));
    setProjectNameOptions([...new Set(allData.map((item) => item["Project Name"]))].filter((p) => p));

    onGenderChange('');
    onAgeRangeChange('');
    onProjectNameChange('');
  };

  return (
    <aside className="hd-impact-sidebar">
      <nav>
        <h1 className="hd-impact-sidebar-header">Heat map based on</h1>
        <HDImpactDropdown label="HDs Trained" options={['HDs Trained']} />
        <h2 className="hd-impact-sidebar-subheader">Filter Data By</h2>
        <ul className="hd-impact-sidebar-list">
          <li className="hd-impact-sidebar-item">
            <HDImpactDropdown
              label="Select"
              options={genderOptions}
              header="Gender"
              selectedOption={selectedGender}
              onChange={handleGenderSelection}
              onClear={() => clearSelection('gender')}
            />
          </li>
          <li className="hd-impact-sidebar-item">
            <HDImpactDropdown
              label="Select"
              options={ageRangeOptions}
              header="Age Range"
              selectedOption={selectedAgeRange}
              onChange={handleAgeRangeSelection}
              onClear={() => clearSelection('ageRange')}
            />
          </li>
          <li className="hd-impact-sidebar-item">
            <HDImpactDropdown
              label="Select"
              options={projectNameOptions}
              header="Project Name"
              selectedOption={selectedProjectName}
              onChange={handleProjectNameSelection}
              onClear={() => clearSelection('projectName')}
            />
          </li>
        </ul>
        <button className="clear-all-filters-button" onClick={handleClearAllFilters}>
          Clear All Filters
        </button>
      </nav>
    </aside>
  );
};

export default HDImpactSidebar;
