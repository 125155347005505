import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';

const Header = ({ handleLogout }) => {  // Receive handleLogout as a prop
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState('Operations');

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName === 'Planning') {
      navigate('/historical');
    } else {
      navigate('/');
    }
  };

  return (
    <header className="header">
      <h1>Project Summary Dashboard</h1>
      <div className="toggle-container">
        {/* Add buttons for Operations and Planning if needed */}
      </div>
      <div className="header-actions">
        {/* Logout button */}
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </header>
  );
};

export default Header;
