import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './CitizenImpactMap.css';
import L from 'leaflet';

const CitizenImpactMap = ({ selectedGender, selectedAgeRange }) => {
  const [geoData, setGeoData] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const mapRef = useRef(null);

  useEffect(() => {
    // Fetch the GeoJSON data for the map
    fetch('/india.json')
      .then(response => response.json())
      .then(data => {
        setGeoData(data);
        if (mapRef.current) {
          const map = mapRef.current;
          const bounds = L.geoJSON(data).getBounds();
          map.fitBounds(bounds);
        }
      })
      .catch(error => console.error('Error loading the GeoJSON data:', error));

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('/citizenimpact.json'); // Fetch citizen impact data
        if (!response.ok) throw new Error('Failed to load Citizen impact data');
        const data = await response.json();

        // Aggregate data based on selected gender and age range
        const aggregatedData = data.reduce((acc, curr) => {
          if (!curr['st_nm'] || !curr['Gender']) return acc;

          const genderMatch = !selectedGender || curr['Gender'].toLowerCase() === selectedGender.toLowerCase();
          const ageRangeMatch = !selectedAgeRange || curr['Age Range'] === selectedAgeRange;

          if (genderMatch && ageRangeMatch) {
            const stateName = curr['st_nm'] ? curr['st_nm'].trim().toLowerCase() : 'unknown';
            const citizenCount = curr['Total Citizens'] || 0; // Total Citizens count
            const gender = curr['Gender'].toLowerCase();

            if (!acc[stateName]) {
              acc[stateName] = {
                state: curr['st_nm'].trim() || 'unknown',
                maleCount: 0,
                femaleCount: 0,
                othersCount: 0,
                totalCitizenCount: 0, // Total citizen count
              };
            }

            // Aggregate counts based on gender
            if (gender === 'male') {
              acc[stateName].maleCount += citizenCount;
            } else if (gender === 'female') {
              acc[stateName].femaleCount += citizenCount;
            } else {
              acc[stateName].othersCount += citizenCount;
            }

            acc[stateName].totalCitizenCount += citizenCount; // Update total citizen count
          }

          return acc;
        }, {});

        // Log the filtered aggregated data to the console
        console.log("Filtered Data:", Object.values(aggregatedData));

        setStateData(Object.values(aggregatedData));
      } catch (error) {
        console.error('Error loading state data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedGender, selectedAgeRange]); // Dependency array updated

  // Function to determine color based on citizen count
  const getColor = (value) => {
    return value > 500000 ? '#4B0B8E' : // Dark Violet for values above 500,000
           value > 400000 ? '#5C2A91' : // Dark Violet
           value > 300000 ? '#6D4E94' : // Violet
           value > 200000 ? '#7E71A1' : // Medium Violet
           value > 150000 ? '#8E94B0' : // Light Violet
           value > 100000 ? '#A6B8C1' : // Soft Violet
           value > 75000  ? '#B8C9D5' : // Very Light Violet
           value > 50000  ? '#C7D1E5' : // Pale Violet
           value > 40000  ? '#D1D8E9' : // Lighter Violet
           value > 30000  ? '#E0E7F3' : // Light Lavender
           value > 20000  ? '#E5EAF5' : // Very Light Lavender
           value > 15000  ? '#F2F3F9' : // Almost White Lavender
           value > 10000  ? '#F8F9FC' : // Near White Lavender
           value > 7500   ? '#FAFAFE' : // Very Pale Lavender
           value > 5000   ? '#FCFCFF' : // Lightest Lavender
           value > 1000   ? '#F7F7FF' : // Almost White
           value > 500    ? '#E8E8E8' : // Very Light Grey
           value > 250    ? '#D1D1D1' : // Light Grey
           value > 100    ? '#B2B2B2' : // Grey
           value > 50     ? '#9C9C9C' : // Dark Grey
                          '#7F7F7F';  // Default color for zero and below (Dark Grey)
  };
  
  

  // Function to style each state based on aggregated data
  const style = (feature) => {
    const stateName = feature.properties.st_nm.toLowerCase();
    const state = stateData.find(s => s.state.toLowerCase() === stateName);
    const totalCount = state ? (state.maleCount + state.femaleCount + state.othersCount) : 0;

    const defaultColor = '#cccccc';

    return {
      fillColor: state ? getColor(totalCount) : defaultColor,
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7,
      interactive: true,
    };
  };

  // Function to handle popup content for each state
  const onEachFeature = (feature, layer) => {
    const stateName = feature.properties.st_nm.toLowerCase();
    const state = stateData.find(s => s.state.toLowerCase() === stateName);

    if (state) {
      const { maleCount, femaleCount, othersCount, totalCitizenCount } = state; // Use total citizen count
      const totalCount = maleCount + femaleCount + othersCount;

      let popupContent = `
      <div class="popup-header">
        <b>${feature.properties.st_nm.toUpperCase()}</b>
      </div>
      <div class="popup-body">
        <div class="popup-row">
          <span class="popup-label">Males:</span>
          <span class="popup-value">${maleCount.toLocaleString()}</span>
        </div>
        <div class="popup-row">
          <span class="popup-label">Females:</span>
          <span class="popup-value">${femaleCount.toLocaleString()}</span>
        </div>
        <div class="popup-row">
          <span class="popup-label">Others:</span>
          <span class="popup-value">${othersCount.toLocaleString()}</span>
        </div>
        
        <div class="popup-row popup-total">
          <span class="popup-label">Total:</span>
          <span class="popup-value">${totalCount.toLocaleString()}</span>
        </div>
      </div>
    `;
    
    // Rest of the code remains unchanged
    layer.bindPopup(popupContent);
    
    // Add hover and click events for the popups
    layer.on({
      click: function () {
        layer.openPopup();
      }
    });
    
    }
  };

  return (
    <div className="citizen-impact-map">
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <MapContainer
          center={[22.9734, 82.6]}
          zoom={5}
          style={{ height: '100%', width: '100%' }}
          scrollWheelZoom={false}
          doubleClickZoom={false}
          zoomControl={false}
          whenCreated={(mapInstance) => {
            mapRef.current = mapInstance;
            mapInstance.setView([22.9734, 82.6], 4.5);
          }}
        >
          {geoData && (
            <GeoJSON
              key={`${selectedGender}-${selectedAgeRange}`} // Triggers re-render on filter change
              data={geoData}
              style={style}
              onEachFeature={onEachFeature}
            />
          )}
        </MapContainer>
      )}
    </div>
  );
};

export default CitizenImpactMap;
