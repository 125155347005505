import React from 'react';
import { GoogleLogin as OAuthLogin } from '@react-oauth/google';
import './GoogleLogin.css';

const GoogleLogin = ({ onLoginSuccess, onLoginFailure }) => {
  return (
    <div className="google-login-container">
      <div className="login-box">
        <div className="loginheader">
          <h1>Welcome to Haqdarshak</h1>
          <p className="loginsub-header">Every Citizen Matters.</p>
        </div>
        <div className="login-section">
          <p>Please Sign In</p>
          <OAuthLogin
            onSuccess={onLoginSuccess}
            onError={onLoginFailure}
            className="login-button"
          />
        </div>
      </div>
    </div>
  );
};

export default GoogleLogin;
