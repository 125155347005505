import React from 'react';
import './AllFilterRightSidebar.css';

const AllFilterRightSidebar = ({ topSchemes = [] }) => {
  return (
    <div className="allfilter-rightsidebar">
      <h3>Top Schemes Delivered</h3>
      <p className="allfilter-location">All India</p>
      <div className="allfilter-content">
        {topSchemes.length > 0 ? (
          topSchemes.map((scheme, index) => (
            <div key={index} className="allfilter-card">
              <h4>{scheme.scheme_name}</h4>
              <p className="allfilter-category">{scheme.Project_Name}</p>
              <div className="allfilter-info">
                <span className="allfilter-label">Benefit Received: </span>
                <span>{scheme.BR}</span>
              </div>
              <div className="allfilter-info">
                <span className="allfilter-label">Application Status: </span>
                <span>{scheme.Project_Status}</span>
              </div>
            </div>
          ))
        ) : (
          <p>No schemes match the selected filters.</p>
        )}
      </div>
    </div>
  );
};

export default AllFilterRightSidebar;
