import React, { useState, useEffect } from 'react';
import Map from './Map';
import './ClientDashboard.css';
import RightSidebar from './ClientRightSidebar';
import { ButtonContainer } from './Button';
import ClientSidebar from './ClientSidebar';
import Cards from '../components/Cards';
import Table from './Table';

const ClientDashboard = () => {
  const [filters, setFilters] = useState({
    client: '',
    project: '',
    projectType: '',
    serviceType: '',
    scheme: ''
  });

  const [topSchemes, setTopSchemes] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null); // State for storing the last update timestamp

  useEffect(() => {
    // Fetch the date and time from the date&time.json file in the public folder
    const fetchLastUpdated = async () => {
      try {
        const response = await fetch('/date&time.json');
        const data = await response.json();
        setLastUpdated(new Date(data.updatedAt).toLocaleString()); // Convert ISO format to readable string
      } catch (error) {
        console.error('Error fetching last updated time:', error);
      }
    };

    fetchLastUpdated(); // Call the function when the component mounts
  }, []);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  return (
    <div className="main-content">
      <Cards />
      <div className="clientdashboard">
        <ClientSidebar 
          setFilters={handleFilterChange} 
          onSchemesChange={setTopSchemes} 
        />
        <div className="map-container">
          <ButtonContainer />
          <Map filters={filters} />
        </div>
        <div className="right-sidebar">
          
          {lastUpdated && (
            <div className="last-updated">
              <p>Last updated: {lastUpdated}</p>
            </div>
          )}
          <RightSidebar schemes={topSchemes} />
        </div>
      </div>
      <Table filters={filters} />
    </div>
  );
};

export default ClientDashboard;
