import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MsmeImpactSidebar.css';
import MsmeImpactDropdown from './MsmeImpactDropdown';

const MsmeImpactSidebar = ({ onGenderChange, onAgeRangeChange, onCategoryChange }) => {
  const [allData, setAllData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    gender: '',
    ageRange: '',
    category: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/msmeimpact.json'); // Update the JSON file path if necessary
      const data = await response.json();
      setAllData(data);

      const categories = [...new Set(data.map((item) => item['Category']))].filter((c) => c);
      setCategoryOptions([...categories, 'Citizen Impacted']);

      const genders = [...new Set(data.map((item) => item['Gender']))].filter((g) => g);
      setGenderOptions(genders);
    };
    fetchData();
  }, []);

  const handleGenderChange = (selectedGender) => {
    setSelectedFilters((prev) => ({ ...prev, gender: selectedGender }));
    onGenderChange(selectedGender);
  };

  const handleAgeRangeChange = (selectedAgeRange) => {
    setSelectedFilters((prev) => ({ ...prev, ageRange: selectedAgeRange }));
    onAgeRangeChange(selectedAgeRange);
  };

  const handleCategoryChange = (selectedCategory) => {
    setSelectedFilters((prev) => ({ ...prev, category: selectedCategory }));
    if (selectedCategory === 'Citizen Impacted') {
      navigate('/citizen-impact');
    } else {
      onCategoryChange(selectedCategory);
    }
  };

  const handleClearAllFilters = () => {
    setSelectedFilters({
      gender: '',
      ageRange: '',
      category: '',
    });
    onGenderChange('');
    onAgeRangeChange('');
    onCategoryChange('');
  };

  return (
    <div className="msme-impact-sidebar">
      <h1 className="msme-impact-sidebar-header">Heat map based on</h1>

      <MsmeImpactDropdown
        label="MSMEs Impacted"
        options={categoryOptions}
        onSelect={handleCategoryChange}
        selectedOption={selectedFilters.category}
      />

      <h3 className="msme-impact-sidebar-subheader">Filter Data By</h3>

      <nav>
        <ul>
          <li className="msme-impact-dropdown">
            <MsmeImpactDropdown
              label="Select Gender"
              options={genderOptions}
              onSelect={handleGenderChange}
              header="Gender"
              selectedOption={selectedFilters.gender}
            />
          </li>
          <li className="msme-impact-dropdown">
            <MsmeImpactDropdown
              label="Select Age Range"
              options={[...new Set(allData.map((item) => item['Age Range']))]}
              onSelect={handleAgeRangeChange}
              header="Age Range"
              selectedOption={selectedFilters.ageRange}
            />
          </li>
        </ul>
      </nav>

      <button className="clear-all-filters-button" onClick={handleClearAllFilters}>
        Clear All Filters
      </button>
    </div>
  );
};

export default MsmeImpactSidebar;
