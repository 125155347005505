import React, { useState, useEffect } from 'react';
import CitizenImpactCards from './CitizenImpactCards';
import './CitizenImpactDashboard.css';
import CitizenImpactSidebar from './CitizenImpactSidebar';
import CitizenImpactMap from './CitizenImpactMap';
import CitizenImpactRightSidebar from './CitizenImpactRightSidebar';
import { CitizenImpactButtonContainer } from './CitizenImpactButton';
import CitizenImpactTable from './CitizenImpactTable';

const CitizenImpactDashboard = () => {
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedAgeRange, setSelectedAgeRange] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null); // State to store the last update timestamp

  const handleCategoryChange = (selectedCategory) => {
    console.log('Selected Category:', selectedCategory);
    // Add any additional logic needed for category changes
  };

  useEffect(() => {
    // Fetch the date and time from the date&time.json file in the public folder
    const fetchLastUpdated = async () => {
      try {
        const response = await fetch('/date&time.json');
        const data = await response.json();
        setLastUpdated(new Date(data.updatedAt).toLocaleString()); // Convert ISO format to readable string
      } catch (error) {
        console.error('Error fetching last updated time:', error);
      }
    };

    fetchLastUpdated(); // Call the function when the component mounts
  }, []); // Empty dependency array ensures this runs only once on component mount

  return (
    <div className="citizenimpact-main-content">
      <CitizenImpactCards />
      <div className="citizenimpact-dashboard">
        <CitizenImpactSidebar 
          onGenderChange={setSelectedGender} 
          onAgeRangeChange={setSelectedAgeRange} 
          onProjectNameChange={setSelectedProjectName}
          onCategoryChange={handleCategoryChange} // Pass the defined function
        />
        <div className="citizenimpact-map-container">
          <CitizenImpactButtonContainer />
          <CitizenImpactMap 
            selectedGender={selectedGender} 
            selectedAgeRange={selectedAgeRange} 
            selectedProjectName={selectedProjectName} 
          />
        </div>
        <div className="citizenimpact-rightsidebar-container">
          {/* Display the last updated timestamp below the right sidebar */}
          {lastUpdated && (
            <div className="last-updated">
              <p>Last updated: {lastUpdated}</p>
            </div>
          )}
          <CitizenImpactRightSidebar />
        </div>
      </div>
      <CitizenImpactTable 
        selectedGender={selectedGender} 
        selectedAgeRange={selectedAgeRange} 
      />
    </div>
  );
};

export default CitizenImpactDashboard;
