// src/components/HistDropdown.js
import React, { useState, useRef, useEffect } from 'react';
import './HistDropdown.css'; // You can use the same CSS file for styling

const HistDropdown = ({ label, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="histdropdown" ref={dropdownRef}>
      <div className="dropdown-label" onClick={handleToggle}>
        {label}
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>&#9660;</span>
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          {options.map((option, index) => (
            <div key={index} className="dropdown-item">
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HistDropdown;
