import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './HDImpactButton.css';

const HDImpactButton = ({ label, path, className, isActive, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick(); // Update the active button
    navigate(path); // Navigate to the respective path
  };

  return (
    <button
      className={`hd-impact-dashboard-button ${className} ${isActive ? 'active' : ''}`}
      onClick={handleClick}
    >
      {label}
    </button>
  );
};

const HDImpactButtonContainer = () => {
  const location = useLocation(); // Get the current route
  const [activeButton, setActiveButton] = useState('');

  const buttons = [
    { label: 'Explore by Project attributes', path: '/', className: 'project-impact-hd-button' },
    { label: 'Explore by HD attributes', path: '/hd-impact', className: 'hd-impact-hd-button' },
    { label: 'Explore by Citizen attributes', path: '/citizen-impact', className: 'citizen-submitted-hd-button' },
  ];

  // Automatically set the active button based on the current route
  useEffect(() => {
    const currentPath = location.pathname;
    const activeButton = buttons.find((button) => button.path === currentPath)?.label || 'Project Impact';
    setActiveButton(activeButton);
  }, [location.pathname]);

  return (
    <div className="hd-impact-button-container">
      {buttons.map((button) => (
        <HDImpactButton
          key={button.label}
          label={button.label}
          path={button.path}
          className={button.className}
          isActive={activeButton === button.label} // Determine if the button is active
          onClick={() => setActiveButton(button.label)} // Update active button on click
        />
      ))}
    </div>
  );
};

export { HDImpactButton, HDImpactButtonContainer };
