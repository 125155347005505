import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.css';
import L from 'leaflet';

const Map = ({ filters }) => {
  const [geoData, setGeoData] = useState(null);
  const [aggregatedStateData, setAggregatedStateData] = useState({});
  const [selectedState, setSelectedState] = useState(null);
  const mapRef = useRef(null);
  const layerRef = useRef(null);

  // Fetch GeoJSON data
  useEffect(() => {
    const fetchGeoData = async () => {
      try {
        const response = await fetch('/india.json');
        if (!response.ok) throw new Error('Failed to load GeoJSON data');
        const data = await response.json();
        console.log('GeoData:', data); // Log GeoData
        setGeoData(data);

        if (mapRef.current) {
          const bounds = L.geoJSON(data).getBounds();
          mapRef.current.fitBounds(bounds);
        }
      } catch (error) {
        console.error('Error loading the GeoJSON data:', error);
      }
    };

    fetchGeoData();
  }, []);

  // Fetch and aggregate state data based on filters
  useEffect(() => {
    const fetchStateData = async () => {
      try {
        const response = await fetch('/project_data.json');
        if (!response.ok) throw new Error('Failed to load project data');
        const data = await response.json();
        console.log('Project Data:', data); // Log project data

        const filteredData = data.filter((item) => {
          const matchesClient = filters.client ? item.Project_Name === filters.client : true;
          const matchesState = filters.state ? item.st_nm === filters.state : true;
          const matchesScheme = filters.scheme ? item.scheme_name === filters.scheme : true;
          return matchesClient && matchesState && matchesScheme;
        });

        // Aggregate data by state and client or scheme
        const aggregatedData = filteredData.reduce((acc, item) => {
          const stateName = item.st_nm;
          const clientOrSchemeKey = filters.client ? item.Project_Name : item.scheme_name;
          
          // Initialize the state if not already present
          if (!acc[stateName]) {
            acc[stateName] = {};
          }

          // Initialize the client or scheme within the state if not already present
          if (!acc[stateName][clientOrSchemeKey]) {
            acc[stateName][clientOrSchemeKey] = {
              OpenCount: 0,
              submitted: 0,
              BR: 0,
              BV: 0,
              Citizen_impacted: 0,
              Number_of_Haqdarshaks_Trained: 0,
              Number_of_total_msme_reached: 0,
            };
          }

          // Aggregate values
          acc[stateName][clientOrSchemeKey].OpenCount += item.OpenCount || 0;
          acc[stateName][clientOrSchemeKey].submitted += item.submitted || 0;
          acc[stateName][clientOrSchemeKey].BR += item.BR || 0;
          acc[stateName][clientOrSchemeKey].BV += item.BV || 0;
          acc[stateName][clientOrSchemeKey].Citizen_impacted += item.Citizen_impacted || 0;
          acc[stateName][clientOrSchemeKey].Number_of_Haqdarshaks_Trained += item.Number_of_Haqdarshaks_Trained || 0;
          acc[stateName][clientOrSchemeKey].Number_of_total_msme_reached += item['Number_of_total_msme_reached/screened'] || 0;

          return acc;
        }, {});

        setAggregatedStateData(aggregatedData);
        setSelectedState(null); // Reset selected state when filters change

        console.log('Aggregated Data:', aggregatedData); // Log the correct aggregated data
      } catch (error) {
        console.error('Error loading the state data:', error);
      }
    };

    fetchStateData();
  }, [filters]);

  // Define color function based on value
  const getColor = (value) => {
    return value > 100000 ? '#2E004B' :  // Darkest violet
    value > 50000  ? '#44006B' :  // Slightly lighter
    value > 20000  ? '#560080' :  // Dark violet
    value > 10000  ? '#6D00A3' :  // Violet
    value > 5000   ? '#7F00BF' :  // Lighter violet
    value > 2000   ? '#9400E0' :  // Bright violet
    value > 1000   ? '#AA33FF' :  // Light violet
    value > 500    ? '#BF66FF' :  // Lighter violet
    value > 200    ? '#D28CFF' :  // Pale violet
    value > 100    ? '#E6B3FF' :  // Very pale violet
                    '#F7E5FF';    // Lightest violet
  };

  // Define style for GeoJSON features
  const style = (feature) => {
    const state = aggregatedStateData[feature.properties.st_nm];
    const isSelected = selectedState && selectedState.properties.st_nm === feature.properties.st_nm;
  
    // Use the scheme filter to determine the data key
    const dataKey = filters.client ? filters.client : filters.scheme;
    let value = state ? state[dataKey]?.OpenCount : 0; // Check if data exists for the scheme
  
    console.log(`State: ${feature.properties.st_nm}, Value: ${value}`);
  
    // ... rest of the style logic using value
  

    return {
      fillColor: getColor(value),
      weight: isSelected ? 4 : 2,
      opacity: 1,
      color: isSelected ? 'purple' : 'white',
      dashArray: isSelected ? '' : '2',
      fillOpacity: 0.7,
      interactive: true
    };
  };

  // Define onEachFeature function to handle click events and popups
  const onEachFeature = (feature, layer) => {
    const st_nm = feature.properties.st_nm;
    const clientOrSchemeKey = filters.client ? filters.client : filters.scheme;
    const stateData = aggregatedStateData[st_nm];
  
    let popupContent = `<div class="popup-container"><h3 class="state-name">${st_nm}</h3><ul class="stats-list">`;
  
    if (stateData && stateData[clientOrSchemeKey]) {
      const stateClientOrSchemeData = stateData[clientOrSchemeKey];
  
      popupContent += `
        <li>Application opened: <span>${stateClientOrSchemeData.OpenCount.toLocaleString()}</span></li>
        <li>Applications submitted: <span>${stateClientOrSchemeData.submitted.toLocaleString()}</span></li>
        <li>Benefit received: <span>${stateClientOrSchemeData.BR.toLocaleString()}</span></li>
        <li>Benefits unlocked: <span>₹${stateClientOrSchemeData.BV.toLocaleString()}</span></li>
       
      `;
    } else {
      popupContent += `<li>No data available for this state and client or scheme.</li>`;
    }
  
    popupContent += '</ul></div>';
  
    layer.bindPopup(popupContent);
  
    layer.on({
      click: () => {
        setSelectedState(feature);
        layer.openPopup();
      },
    });
  
    if (!selectedState && !layerRef.current) {
      layerRef.current = layer;
      layer.openPopup();
    }
  };

  return (
    <div className="map">
       <MapContainer
  center={[22.9734, 82.6]}
  zoom={5}
  style={{ height: '100%', width: '100%' }}
  scrollWheelZoom={false}  // Disable zooming with scroll wheel
  doubleClickZoom={false}  // Disable zooming with double click
  zoomControl={false}      // Disable zoom control on the map
 // dragging={false}         // Disable dragging
  whenCreated={(mapInstance) => {
    mapRef.current = mapInstance;
    mapInstance.setView([22.9734, 82.6], 4.5); // Set initial view and zoom
  }}
>
        {geoData && (
          <GeoJSON
            data={geoData}
            style={style}
            onEachFeature={onEachFeature}
            key={JSON.stringify(filters)} // Ensure GeoJSON is re-rendered on filter change
          />
        )}
      </MapContainer>
    </div>
  );
};

export default Map;
