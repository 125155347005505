import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './HDImpactMap.css';
import L from 'leaflet';

const HDImpactMap = ({ selectedGender, selectedAgeRange, selectedProjectName }) => {
  const [geoData, setGeoData] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const mapRef = useRef(null);

  useEffect(() => {
    // Fetch GeoJSON data
    fetch('/india.json')
      .then(response => response.json())
      .then(data => {
        setGeoData(data);
        if (mapRef.current) {
          const map = mapRef.current;
          const bounds = L.geoJSON(data).getBounds();
          map.fitBounds(bounds);
        }
      })
      .catch(error => console.error('Error loading the GeoJSON data:', error));

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('/hdimpact.json');
        if (!response.ok) throw new Error('Failed to load HD impact data');
        const data = await response.json();

        const aggregatedData = data.reduce((acc, curr) => {
          if (!curr['st_nm'] || !curr['HD Gender']) return acc;

          const genderMatch = !selectedGender || curr['HD Gender'].toLowerCase() === selectedGender.toLowerCase();
          const ageRangeMatch = !selectedAgeRange || curr['Age Range'] === selectedAgeRange;
          const projectNameMatch = !selectedProjectName || curr['Project Name'] === selectedProjectName;

          if (genderMatch && ageRangeMatch && projectNameMatch) {
            const stateName = curr['st_nm'] ? curr['st_nm'].trim().toLowerCase() : 'unknown';
            const hdCount = curr['Distinct User Count'] || 0;
            const activeHD = parseInt(curr['Active HD']) || 0;
            const gender = curr['HD Gender'].toLowerCase();

            if (!acc[stateName]) {
              acc[stateName] = {
                state: curr['st_nm'].trim() || 'unknown',
                maleCount: 0,
                femaleCount: 0,
                othersCount: 0,
                activeHDCount: 0,
              };
            }

            if (gender === 'male') {
              acc[stateName].maleCount += hdCount;
            } else if (gender === 'female') {
              acc[stateName].femaleCount += hdCount;
            } else {
              acc[stateName].othersCount += hdCount;
            }

            acc[stateName].activeHDCount += activeHD;
          }

          return acc;
        }, {});

        // Log the aggregated data to the console
        console.log('Filtered State Data:', Object.values(aggregatedData));

        setStateData(Object.values(aggregatedData));
      } catch (error) {
        console.error('Error loading state data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedGender, selectedAgeRange, selectedProjectName]);

  const getColor = (value) => {
    return value > 8000  ? '#4C2C77' :
       value > 7000  ? '#5A3A8E' :
       value > 6000  ? '#6A48A6' :
       value > 5000  ? '#7B56BF' :
       value > 4000  ? '#8C64D8' :
       value > 3000  ? '#9E73E1' :
       value > 2000  ? '#B083EA' :
       value > 1500  ? '#C194F3' :
       value > 1000  ? '#D3A5FC' :
       value > 750   ? '#E4B6FF' :
       value > 500   ? '#F0C9FF' :
       value > 250   ? '#F7D6FF' :
       value > 100   ? '#FBE3FF' :
       value > 50    ? '#FDF0FF' :
       value > 0     ? '#FDF7FF' :
                       '#F0E6FF';
  };

  const style = (feature) => {
    const stateName = feature.properties.st_nm.toLowerCase();
    const state = stateData.find(s => s.state.toLowerCase() === stateName);
    const totalCount = state ? (state.maleCount + state.femaleCount + state.othersCount) : 0;

    const defaultColor = '#cccccc';

    return {
      fillColor: state ? getColor(totalCount) : defaultColor,
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7,
      interactive: true,
    };
  };

  const onEachFeature = (feature, layer) => {
    const stateName = feature.properties.st_nm.toLowerCase();
    const state = stateData.find(s => s.state.toLowerCase() === stateName);
  
    if (state) {
      const { maleCount, femaleCount, othersCount, activeHDCount } = state;
      const totalCount = maleCount + femaleCount + othersCount;
  
      let popupContent = `
        <div class="popup-header">
          <b>${feature.properties.st_nm.toUpperCase()}</b>
        </div>
        <div class="popup-body">
          <div class="popup-row">
            <span class="popup-label">Males:</span>
            <span class="popup-value">${maleCount}</span>
          </div>
          <div class="popup-row">
            <span class="popup-label">Females:</span>
            <span class="popup-value">${femaleCount}</span>
          </div>
          <div class="popup-row">
            <span class="popup-label">Others:</span>
            <span class="popup-value">${othersCount}</span>
          </div>
          <div class="popup-row">
            <span class="popup-label">Active HDs:</span>
            <span class="popup-value">${activeHDCount}</span>
          </div>
          <div class="popup-row popup-total">
            <span class="popup-label">Total:</span>
            <span class="popup-value">${totalCount}</span>
          </div>
        </div>
      `;
  
      layer.bindPopup(popupContent);
  
      // Only add the click event for displaying the popup
      layer.on({
        click: function () {
          layer.openPopup();
        }
      });
    } else {
      layer.bindPopup(`<b>${feature.properties.st_nm.toUpperCase()}</b><br/>No data available`);
  
      // Only add the click event for displaying the popup when there's no data
      layer.on({
        click: function () {
          layer.openPopup();
        }
      });
    }
  };
  

  return (
    <div className="hd-impact-map">
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <MapContainer
          center={[22.9734, 82.6]}
          zoom={5}
          style={{ height: '100%', width: '100%' }}
          scrollWheelZoom={false}
          doubleClickZoom={false}
          zoomControl={false}
          whenCreated={(mapInstance) => {
            mapRef.current = mapInstance;
            mapInstance.setView([22.9734, 82.6], 4.5);
          }}
        >
          {geoData && (
            <GeoJSON
              key={`${selectedGender}-${selectedAgeRange}-${selectedProjectName}`}
              data={geoData}
              style={style}
              onEachFeature={onEachFeature}
            />
          )}
        </MapContainer>
      )}
    </div>
  );
};

export default HDImpactMap;
