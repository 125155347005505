import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SchemeSidebar.css';
import Dropdown from './SchemeDropdown';

const SchemeSidebar = ({ setFilters, setHeatmapData }) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [schemes, setSchemes] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({
    serviceType: '',
    scheme: '',
    heatmapType: '',
  });

  useEffect(() => {
    fetch('/project_data.json')
      .then(response => response.json())
      .then(data => {
        setData(data);
        setFilteredData(data);
        updateFilters(data);
        if (setHeatmapData) setHeatmapData(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const updateFilters = (data) => {
    const schemeNames = data.map(item => item.scheme_name);
    const serviceTypeNames = data.map(item => item["Type(Sch/Doc)"]);

    setSchemes([...new Set(schemeNames)]);
    setServiceTypes([...new Set(serviceTypeNames)]);
  };

  const handleFilterChange = (filterKey, value) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterKey]: value,
    }));
    setFilters(filterKey, value);

    let newFilteredData = data;

    switch (filterKey) {
      case 'serviceType':
        newFilteredData = data.filter(item => item["Type(Sch/Doc)"] === value);
        const filteredSchemes = new Set(newFilteredData.map(item => item.scheme_name));
        setSchemes([...filteredSchemes]);
        break;
      case 'scheme':
        newFilteredData = data.filter(item => item.scheme_name === value);
        break;
      case 'heatmapType':
        // Implement filtering based on heatmapType
        // ... (your logic for filtering based on heatmapType)
        break;
      default:
        break;
    }

    setFilteredData(newFilteredData);
    updateFilters(newFilteredData);
    if (setHeatmapData) setHeatmapData(newFilteredData);
  };

  const handleClearAllFilters = () => {
    setSelectedFilters({ serviceType: '', scheme: '', heatmapType: '' });
    setFilters('serviceType', '');
    setFilters('scheme', '');
    setFilters('heatmapType', '');

    setFilteredData(data);
    updateFilters(data);
    if (setHeatmapData) setHeatmapData(data);
  };

  const filterOptions = {
    serviceType: serviceTypes,
    scheme: selectedFilters.serviceType ? filteredData.map(item => item.scheme_name) : schemes,
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <aside className="scheme-sidebar">
      <nav>
        <h2 className="scheme-sidebar-header">Filter Data By</h2>
        <ul className="scheme-sidebar-list">
          {Object.keys(selectedFilters).map((filterKey) => (
            filterKey !== 'heatmapType' && (
              <li className="scheme-sidebar-item" key={filterKey}>
                <div className="dropdown-container">
                  <Dropdown
                    label="Select"
                    options={filterOptions[filterKey] || []}
                    header={capitalizeFirstLetter(filterKey)}
                    selectedOption={selectedFilters[filterKey]}
                    onSelect={(value) => handleFilterChange(filterKey, value)}
                  />
                </div>
              </li>
            )
          ))}
        </ul>
        <button className="clear-all-filters-button" onClick={handleClearAllFilters}>
          Clear All Filters
        </button>
        <button className="back-button" onClick={() => navigate(-1)}>
          Back To Home
        </button>
      </nav>
    </aside>
  );
};

export default SchemeSidebar;