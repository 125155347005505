import React from 'react';
import './HistSidebar.css';
import HistDropdown from './HistDropdown';

const HistSidebar = () => {
  return (
    <aside className="histsidebar">
      <nav>
        <h1>Historical Data Filter</h1>
        <ul style={{ listStyleType: 'none' }}>  {/* Remove bullets from the list */}
          <li>
            <HistDropdown label="Heat map based on" options={['Option 1', 'Option 2', 'Option 3']} />
          </li>
          <li>
            <HistDropdown label="Scheme Name" options={['Option 1', 'Option 2', 'Option 3']} />
          </li>
          <li>
            <HistDropdown label="Scheme Category" options={['Option 1', 'Option 2', 'Option 3']} />
          </li>
          <li>
            <HistDropdown label="Scheme budget" options={['Option 1', 'Option 2', 'Option 3']} />
          </li>
          <li>
            <HistDropdown label="Implementation time" options={['Option 1', 'Option 2', 'Option 3']} />
          </li>
          
        </ul>
      </nav>
    </aside>
  );
};

export default HistSidebar;
