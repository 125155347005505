import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import './CitizenImpactRightSidebar.css';
import Chart from 'chart.js/auto';

const CitizenImpactRightSidebar = () => {
  const [genderData, setGenderData] = useState(null); // Initialize to null
  const [ageData, setAgeData] = useState(null); // Initialize to null

  // Fetch data from citizenimpact.json
  useEffect(() => {
    fetch('/citizenimpact.json')
      .then((response) => response.json())
      .then((data) => {
        // Aggregate the gender data across all entries
        const genderCount = data.reduce((acc, item) => {
          if (item['Gender'] === 'Male') acc.Male += item['Total Citizens'] || 0;
          else if (item['Gender'] === 'Female') acc.Female += item['Total Citizens'] || 0;
          else if (item['Gender'] === 'Other') acc.Others += item['Total Citizens'] || 0; // Adjusted for 'Other'
          return acc;
        }, { Male: 0, Female: 0, Others: 0 });

        // Aggregate the age range data across all entries
        const ageCount = data.reduce((acc, item) => {
          if (item['Age Range'] === '25 and Under') acc['Under 25'] += item['Total Citizens'] || 0; // Mapped to 'Under 25'
          else if (item['Age Range'] === '26-35') acc['26-35'] += item['Total Citizens'] || 0;
          else if (item['Age Range'] === '36-45') acc['36-45'] += item['Total Citizens'] || 0;
          else if (item['Age Range'] === '46-60') acc['46-60'] += item['Total Citizens'] || 0;
          else if (item['Age Range'] === '60+') acc['60+'] += item['Total Citizens'] || 0;
          return acc;
        }, { 'Under 25': 0, '26-35': 0, '36-45': 0, '46-60': 0, '60+': 0 });

        // Set the gender data for the pie chart
        setGenderData({
          labels: ['Male', 'Female', 'Others'],
          datasets: [
            {
              data: [genderCount.Male, genderCount.Female, genderCount.Others],
              backgroundColor: ['#f48fb1', '#ce93d8', '#ba68c8'], // Pink and violet shades
            },
          ],
        });

        // Set the age range data for the pie chart
        setAgeData({
          labels: ['Under 25', '26-35', '36-45', '46-60', '60+'],
          datasets: [
            {
              data: [
                ageCount['Under 25'],
                ageCount['26-35'],
                ageCount['36-45'],
                ageCount['46-60'],
                ageCount['60+'],
              ],
              backgroundColor: ['#f8bbd0', '#f48fb1', '#ce93d8', '#ba68c8', '#ab47bc'], // Pink to violet gradient
            },
          ],
        });
      })
      .catch((error) => console.error('Error fetching the data:', error));
  }, []);

  return (
    <div className="citizen-impact-rightsidebar">
      <div className="citizen-impact-content">
        <h4>Gender Distribution</h4>
        {genderData ? <Pie data={genderData} /> : <p>Loading...</p>}

        <h4>Age Range Distribution</h4>
        {ageData ? <Pie data={ageData} /> : <p>Loading...</p>}
      </div>
    </div>
  );
};

export default CitizenImpactRightSidebar;
