import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.css';
import L from 'leaflet';

const Map = ({ filters }) => {
  const [geoData, setGeoData] = useState(null);
  const [filteredStateData, setFilteredStateData] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const mapRef = useRef(null);
  const layerRef = useRef(null);

  // Define the getColor function here
  const getColor = (value, type) => {
    switch (type) {
      case 'Application Opened':
        return value > 500000 ? '#5C0994' :  // Dark violet
        value > 400000 ? '#6D12A6' :  // Slightly lighter dark violet
        value > 300000 ? '#7E1BB8' :  // Medium dark violet
        value > 200000 ? '#8F24CA' :  // Medium violet
        value > 150000 ? '#9F2DDB' :  // Light medium violet
        value > 100000 ? '#B036ED' :  // Lighter violet
        value > 75000  ? '#BF4AFF' :  // Light violet
        value > 50000  ? '#C66BFF' :  // Very light violet
        value > 25000  ? '#D4ADFF' :  // Pale violet
        value > 0      ? '#E2E2FF' :  // Very pale violet
                        '#F0E6FF';    // Lightest violet
      case 'Applications Submitted':
        return value > 500000 ? '#5C0994' :  // Dark violet
        value > 400000 ? '#6D12A6' :  // Slightly lighter dark violet
        value > 300000 ? '#7E1BB8' :  // Medium dark violet
        value > 200000 ? '#8F24CA' :  // Medium violet
        value > 150000 ? '#9F2DDB' :  // Light medium violet
        value > 100000 ? '#B036ED' :  // Lighter violet
        value > 75000  ? '#BF4AFF' :  // Light violet
        value > 50000  ? '#C66BFF' :  // Very light violet
        value > 25000  ? '#D4ADFF' :  // Pale violet
        value > 0      ? '#E2E2FF' :  // Very pale violet
                        '#F0E6FF';    // Lightest violet
      case 'Benefits Unlocked':
        return value > 100000000000 ? '#F7F0FF' :  // Light Violet
       value > 94000000000  ? '#E6CCFF' :
       value > 88000000000  ? '#E2E2FF' :
       value > 82000000000  ? '#DBCEFF' :
       value > 76000000000  ? '#D4ADFF' :
       value > 70000000000  ? '#CD8CFF' :
       value > 64000000000  ? '#C66BFF' :
       value > 58000000000  ? '#BF4AFF' :
       value > 52000000000  ? '#B036ED' :
       value > 46000000000  ? '#9F2DDB' :
       value > 40000000000  ? '#8F24CA' :
       value > 34000000000  ? '#7E1BB8' :
       value > 28000000000  ? '#6D12A6' :
       value > 22000000000  ? '#5C0994' :
       value > 16000000000  ? '#4B0082' :  // Dark Violet
       value > 10000000000  ? '#A020F0' :
       value > 1000000000   ? '#B24BF3' :
       value > 100000000    ? '#C576F6' :
       value > 0            ? '#D7A1F9' :
                              '#cccccc';  // Default grey


      case 'Citizens Impacted':
        return value > 4000000 ? '#880ED4' :
               value > 2000000 ? '#A020F0' :
               value > 1000000 ? '#B24BF3' :
               value > 500000 ? '#C576F6' :
               value > 0 ? '#D7A1F9' :
                           '#cccccc';
      case 'MSMEs Impacted':
        return value > 60000 ? '#880ED4' :
               value > 40000 ? '#A020F0' :
               value > 20000 ? '#B24BF3' :
               value > 10000 ? '#C576F6' :
               value > 0 ? '#D7A1F9' :
                           '#cccccc';
      case 'HDs Trained':
        return value > 30000 ? '#880ED4' :
               value > 20000 ? '#A020F0' :
               value > 10000 ? '#B24BF3' :
               value > 5000 ? '#C576F6' :
               value > 0 ? '#D7A1F9' :
                           '#cccccc';
      case 'Benefit Received':
        return value > 500000 ? '#5C0994' :  // Dark violet
           value > 400000 ? '#6D12A6' :  // Slightly lighter dark violet
           value > 300000 ? '#7E1BB8' :  // Medium dark violet
           value > 200000 ? '#8F24CA' :  // Medium violet
           value > 150000 ? '#9F2DDB' :  // Light medium violet
           value > 100000 ? '#B036ED' :  // Lighter violet
           value > 75000  ? '#BF4AFF' :  // Light violet
           value > 50000  ? '#C66BFF' :  // Very light violet
           value > 25000  ? '#D4ADFF' :  // Pale violet
           value > 0      ? '#E2E2FF' :  // Very pale violet
                           '#F0E6FF';    // Lightest violet

                     
      default:
        return '#cccccc';
    }
  };

  // Fetch GeoJSON data
  useEffect(() => {
    const fetchGeoData = async () => {
      try {
        const response = await fetch('/india.json');
        if (!response.ok) throw new Error('Failed to load GeoJSON data');
        const data = await response.json();
        setGeoData(data);

        if (mapRef.current) {
          const bounds = L.geoJSON(data).getBounds();
          mapRef.current.fitBounds(bounds);
        }
      } catch (error) {
        console.error('Error loading the GeoJSON data:', error);
      }
    };

    fetchGeoData();
  }, []);

  // Fetch and filter state data based on filters
  useEffect(() => {
    const fetchStateData = async () => {
      try {
        const response = await fetch('/project_data.json');
        if (!response.ok) throw new Error('Failed to load project data');
        const data = await response.json();

        // Ensure all filter conditions are applied correctly
        const filteredData = data.filter(item => {
          return (
            (filters.project ? item.PROJECT_ID === filters.project : true) &&
            (filters.client ? item.Project_Name === filters.client : true) &&
            (filters.projectType ? item.Project_Type === filters.projectType : true) &&
            (filters.serviceType ? item["Type(Sch/Doc)"] === filters.serviceType : true) &&
            (filters.scheme ? item.scheme_name === filters.scheme : true)
          );
        });

        // Aggregate data by state after filtering
        const aggregatedData = filteredData.reduce((acc, item) => {
          const state = item.st_nm;
        
          // Debugging: Check the incoming item
          console.log("Processing item:", item);
        
          // Initialize the state entry if it doesn't exist
          if (!acc[state]) {
            acc[state] = {
              st_nm: state,
              OpenCount: 0,
              submitted: 0,
              BR: 0,
              BV: 0,
              schemes: new Set(),
              Number_of_Haqdarshaks_Trained: 0,
              msme_reached: 0,
              Citizen_impacted: 0,
            };
          }
        
          // Aggregate values
          acc[state].OpenCount += parseInt(item.OpenCount || 0, 10);
          acc[state].submitted += parseInt(item.submitted || 0, 10);
          acc[state].BR += parseInt(item.BR || 0, 10);
          acc[state].BV += parseInt(item.BV || 0, 10);
          acc[state].schemes.add(item.scheme_name);
        
          // Only set the non-aggregated values once (if not already set)
          if (acc[state].Number_of_Haqdarshaks_Trained === 0 && item.Number_of_Haqdarshaks_Trained) {
            acc[state].Number_of_Haqdarshaks_Trained = parseInt(item.Number_of_Haqdarshaks_Trained, 10);
            console.log(`Setting Number_of_Haqdarshaks_Trained for ${state}:`, acc[state].Number_of_Haqdarshaks_Trained);
          } else if (!item.Number_of_Haqdarshaks_Trained) {
            console.warn(`Number_of_Haqdarshaks_Trained is missing or zero for item:`, item);
          }
        
          if (acc[state].msme_reached === 0 && item['Number_of_total_msme_reached/screened']) {
            acc[state].msme_reached = parseInt(item['Number_of_total_msme_reached/screened'], 10);
            console.log(`Setting msme_reached for ${state}:`, acc[state].msme_reached);
          } else if (!item['Number_of_total_msme_reached/screened']) {
            console.warn(`Number_of_total_msme_reached/screened is missing or zero for item:`, item);
          }
        
          if (acc[state].Citizen_impacted === 0 && item.Citizen_impacted) {
            acc[state].Citizen_impacted = parseInt(item.Citizen_impacted, 10);
            console.log(`Setting Citizen_impacted for ${state}:`, acc[state].Citizen_impacted);
          } else if (!item.Citizen_impacted) {
            console.warn(`Citizen_impacted is missing or zero for item:`, item);
          }
        
          return acc;
        }, {});
        
        const aggregatedArray = Object.values(aggregatedData);
        
        // Update state with the aggregated data
        setFilteredStateData(aggregatedArray);
        setSelectedState(null); // Reset selected state when filters change
        
        console.log("Filtered and aggregated data:", aggregatedArray);
        
        
      } catch (error) {
        console.error('Error loading the state data:', error);
      }
    };

    fetchStateData();
  }, [filters]);

 const style = (feature) => {
    const state = filteredStateData.find(item => item.st_nm === feature.properties.st_nm);
    const isSelected = selectedState && selectedState.properties.st_nm === feature.properties.st_nm;

   

  let value;
  let type = filters.heatmapType;

  if (type === 'Benefits Unlocked') {
    value = state ? state.BV || 0 : 0;
  } else {
    switch (type) {
      case 'Application Opened':
        value = state ? state.OpenCount || 0 : 0;
        break;
      case 'Applications Submitted':
        value = state ? state.submitted || 0 : 0;
        break;
      case 'Benefit Received':
        value = state ? state.BR || 0 : 0;
        break;
      default:
        value = 0;
        break;
    }
  }

  return {
    fillColor: getColor(value, type),
    weight: isSelected ? 4 : 2,
    opacity: 1,
    color: isSelected ? 'purple' : 'white',
    dashArray: isSelected ? '' : '2',
    fillOpacity: 0.7,
    interactive: true
  };
};

const onEachFeature = (feature, layer) => {
  const st_nm = feature.properties.st_nm;
  // Find the correct state data matching the current feature (state boundary)
  const state = filteredStateData.find(item => item.st_nm === st_nm);

  const popupContent = state
    ? `
      <div class="popup-container">
        <h3 class="state-name">${st_nm}</h3>
        <ul class="stats-list">
          <li>Application opened: <span>${(state.OpenCount || 0).toLocaleString()}</span></li>
          <li>Applications submitted: <span>${(state.submitted || 0).toLocaleString()}</span></li>
          <li>Benefit received: <span>${(state.BR || 0).toLocaleString()}</span></li>
          <li>Benefits unlocked: <span>₹${(state.BV || 0).toLocaleString()}</span></li>

          <li>MSMEs reached: <span>${(state.msme_reached || 0).toLocaleString()}</span></li>
          <li>HDs trained: <span>${(state.Number_of_Haqdarshaks_Trained || 0).toLocaleString()}</span></li>
        </ul>
      </div>
    `
    : `<div class="popup-container"><h3 class="state-name">${st_nm}</h3><p>No data available</p></div>`;

  layer.bindPopup(popupContent);

  // Define the hover effects
  layer.on({
    
    click: (e) => {
      setSelectedState(feature);
      const layer = e.target;
      layer.setStyle({
        weight: 4,
        color: 'purple',
        dashArray: '',
        fillOpacity: 0.9
      });
      layer.openPopup();
    }
  });

  if (!selectedState && !layerRef.current) {
    layerRef.current = layer;
    layer.openPopup();
  }
};


return (
  <div className="map">
    <MapContainer
  center={[22.9734, 82.6]}
  zoom={5}
  style={{ height: '100%', width: '100%' }}
  scrollWheelZoom={false}  // Disable zooming with scroll wheel
  doubleClickZoom={false}  // Disable zooming with double click
  zoomControl={false}      // Disable zoom control on the map
 // dragging={false}         // Disable dragging
  whenCreated={(mapInstance) => {
    mapRef.current = mapInstance;
    mapInstance.setView([22.9734, 82.6], 4.5); // Set initial view and zoom
  }}
>
  {geoData && (
    <GeoJSON
      key={JSON.stringify(filters)} // Re-render on filter change
      data={geoData}
      style={style}
      onEachFeature={onEachFeature}
    />
  )}
</MapContainer>

  </div>
);
};

export default Map;
