import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './AllFilterDropdown.css'; // Ensure this file exists and is styled accordingly

const AllFilterDropdown = ({ label = "Select an option", options = [], onSelect, header = "", selectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  // Toggles the dropdown open/closed
  const handleToggle = () => {
    setIsOpen(prev => !prev);
  };

  // Closes dropdown when clicking outside of it
  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);

  // Handles option selection
  const handleOptionClick = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  // Filters options based on the search term
  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Clears the search term
  const handleClearSearch = () => {
    setSearchTerm('');
  };

  // Set up event listener for detecting clicks outside the dropdown
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  // Handles closing dropdown with Escape key
  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  }, []);

  // Set up event listener for Escape key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className="all-filter-dropdown-container">
      {header && <div className="all-filter-dropdown-header">{header}</div>}
      <div className="all-filter-dropdown" ref={dropdownRef} role="listbox">
        <div
          className="all-filter-dropdown-label"
          onClick={handleToggle}
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          tabIndex={0}
        >
          {selectedOption || label}
          <span className={`all-filter-dropdown-arrow ${isOpen ? 'open' : ''}`}>&#9660;</span>
        </div>
        {isOpen && (
          <div className="all-filter-dropdown-menu" role="listbox">
            <div className="all-filter-dropdown-search-container">
              <input
                type="text"
                className="all-filter-dropdown-search"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {searchTerm && (
                <span
                  className="all-filter-dropdown-clear"
                  onClick={handleClearSearch}
                  role="button"
                  aria-label="Clear filter"
                >
                  &#10005; {/* Clear filter icon */}
                </span>
              )}
            </div>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={index}
                  className="all-filter-dropdown-item"
                  role="option"
                  onClick={() => handleOptionClick(option)}
                  tabIndex={0}
                  aria-selected={selectedOption === option}
                >
                  {option}
                </div>
              ))
            ) : (
              <div className="all-filter-dropdown-no-results">No results found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

AllFilterDropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func,
  header: PropTypes.string,
  selectedOption: PropTypes.string,
};

// Default prop for onSelect to ensure it's always a function
AllFilterDropdown.defaultProps = {
  onSelect: () => {},
  selectedOption: ''
};

export default AllFilterDropdown;
