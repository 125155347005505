import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './CitizenImpactDropdown.css';

const CitizenImpactDropdown = ({ label = "Select an option", options = [], onSelect = () => {}, header = "", selectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);

  const handleOptionClick = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="citizen-impact-dropdown-container">
      {header && <div className="citizen-impact-dropdown-header">{header}</div>}
      <div className="citizen-impact-dropdown" ref={dropdownRef} role="listbox">
        <div
          className="citizen-impact-dropdown-label"
          onClick={handleToggle}
          aria-expanded={isOpen}
          aria-haspopup="listbox"
        >
          {selectedOption || label}
          <span className={`citizen-impact-dropdown-arrow ${isOpen ? 'open' : ''}`}>&#9660;</span>
        </div>
        {isOpen && (
          <div className="citizen-impact-dropdown-menu" role="listbox">
            {options.map((option, index) => (
              <div
                key={index}
                className="citizen-impact-dropdown-item"
                role="option"
                onClick={() => handleOptionClick(option)}
                tabIndex={0}
                aria-selected={selectedOption === option}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

CitizenImpactDropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  header: PropTypes.string,
  selectedOption: PropTypes.string,
};

export default CitizenImpactDropdown;
