import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import './CitizenImpactTable.css';

const CitizenImpactTable = ({ selectedGender, selectedAgeRange, selectedProjectName }) => {
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/citizenimpact.json'); // Adjusted file name for Citizen Impact data
        if (!response.ok) throw new Error('Failed to load Citizen Impact data');
        const data = await response.json();

        // Aggregate data based on gender, age range, and project name
        const aggregatedData = data.reduce((acc, curr) => {
          // Check if the state name and gender exist
          if (!curr['st_nm'] || !curr['Gender']) return acc;

          const genderMatch = !selectedGender || curr['Gender'].toLowerCase() === selectedGender.toLowerCase();
          const ageRangeMatch = !selectedAgeRange || curr['Age Range'] === selectedAgeRange;
          const projectNameMatch = !selectedProjectName || curr['Project Name'] === selectedProjectName;

          if (genderMatch && ageRangeMatch && projectNameMatch) {
            // Handle potential null or missing state names
            const stateName = curr['st_nm'] ? curr['st_nm'].trim().toLowerCase() : 'unknown';
            const totalCitizens = curr['Total Citizens'] || 0;
            const gender = curr['Gender'].toLowerCase();

            if (!acc[stateName]) {
              acc[stateName] = {
                state: curr['st_nm'] ? curr['st_nm'].trim() : 'unknown',
                maleCount: 0,
                femaleCount: 0,
                othersCount: 0,
                totalCitizensCount: 0, // Initialize total citizens count
              };
            }

            // Aggregate the citizen counts by gender
            if (gender === 'male') {
              acc[stateName].maleCount += totalCitizens;
            } else if (gender === 'female') {
              acc[stateName].femaleCount += totalCitizens;
            } else {
              acc[stateName].othersCount += totalCitizens;
            }

            acc[stateName].totalCitizensCount += totalCitizens; // Add total citizens count
          }

          return acc;
        }, {});

        setStateData(Object.values(aggregatedData));
      } catch (error) {
        console.error('Error loading state data:', error);
      }
    };

    fetchData();
  }, [selectedGender, selectedAgeRange, selectedProjectName]);

  // Export data to Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(stateData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Citizen Impact Data');
    XLSX.writeFile(workbook, 'citizen_impact_data.xlsx');
  };

  return (
    <div className="table-container">
      <button onClick={exportToExcel} className="export-btn">Export to Excel</button>
      <table>
        <thead>
          <tr>
            <th>State</th>
            <th>Male Citizens</th>
            <th>Female Citizens</th>
            <th>Other Citizens</th>
            <th>Total Citizens</th>
          </tr>
        </thead>
        <tbody>
          {stateData.map((data, index) => (
            <tr key={index}>
              <td>{data.state || 'Unknown'}</td> {/* Handle case where state might be unknown */}
              <td>{data.maleCount.toLocaleString()}</td>
              <td>{data.femaleCount.toLocaleString()}</td>
              <td>{data.othersCount.toLocaleString()}</td>
              <td>{data.totalCitizensCount.toLocaleString()}</td> {/* Render Total Citizens count */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CitizenImpactTable;
