// src/components/MsmeImpactButton.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MsmeImpactButton.css';

const MsmeImpactButton = ({ label, path, className, isActive }) => {
  const navigate = useNavigate();

  return (
    <button
      className={`msme-impact-dashboard-button ${className} ${isActive ? 'active' : ''}`}
      onClick={() => navigate(path)}
    >
      {label}
    </button>
  );
};

const MsmeImpactButtonContainer = () => {
  const [activeButton, setActiveButton] = useState('Project Impact');

  const buttons = [
    { label: 'Explore by Project attributes', path: '/', className: 'project-impact-msme-button' },
    { label: 'Explore by HD attributes', path: '/hd-impact', className: 'hd-impact-msme-button' },
    { label: 'Explore by Citizen attributes', path: '/citizen-impact', className: 'citizen-impact-button' },
  ];

  return (
    <div className="msme-impact-button-container">
      {buttons.map((button) => (
        <MsmeImpactButton
          key={button.label}
          label={button.label}
          path={button.path}
          className={button.className}
          isActive={activeButton === button.label}
          onClick={() => setActiveButton(button.label)}
        />
      ))}
    </div>
  );
};

export { MsmeImpactButton, MsmeImpactButtonContainer };
