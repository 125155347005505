import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Button.css';

const Button = ({ label, route, className, isActive, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick(); // Update the active button state
    navigate(route); // Navigate to the selected route
  };

  return (
    <button
      className={`dashboard-button ${className} ${isActive ? 'active' : ''}`}
      onClick={handleClick}
    >
      {label}
    </button>
  );
};

const ButtonContainer = () => {
  const location = useLocation(); // Get the current route
  const [activeButton, setActiveButton] = useState('');

  const buttons = [
    { label: 'Explore by Project attributes', route: '/', className: 'project-impact-button' },
    { label: 'Explore by HD attributes', route: '/hd-impact', className: 'hd-impact-button' },
    { label: 'Explore by Citizen attributes', route: '/citizen-impact', className: 'citizen-impact-button' },
  ];

  // Automatically set the active button based on the current route
  useEffect(() => {
    const currentPath = location.pathname;
    const activeButtonLabel = buttons.find((button) => button.route === currentPath)?.label || 'Project Impact';
    setActiveButton(activeButtonLabel);
  }, [location.pathname]); // Re-run this effect whenever the route changes

  return (
    <div className="button-container">
      {buttons.map((button) => (
        <Button
          key={button.label}
          label={button.label}
          route={button.route}
          className={button.className}
          isActive={activeButton === button.label} // Determine if the button is active
          onClick={() => setActiveButton(button.label)} // Update active button on click
        />
      ))}
    </div>
  );
};

export { Button, ButtonContainer };
