// src/components/Historical.js

import React from 'react';
import './Historical.css'
import HistSidebar from './HistSidebar';
import HistMap from './HistMap';
import HistRightSidebar from './HistRightSidebar';

const Historical = () => {
    return (
        <div className="historical">
      <HistSidebar/>
     <HistMap/>
     <HistRightSidebar/>
    </div>
    );
};

export default Historical;
