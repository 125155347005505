import React from 'react';
import './RightSidebar.css';

const RightSidebar = ({ topSchemes }) => {
  return (
    <div className="rightsidebar">
      <h3>Top Schemes Delivered</h3>
      <p className="location">All India</p>
      <div className="content">
        {topSchemes.length === 0 ? (
          <p>No schemes available for this filter</p>
        ) : (
          topSchemes.map((scheme, index) => (
            <div key={index} className="card">
              <h4>{scheme.scheme_name}</h4>
              <p className="category">{scheme.Project_Name}</p>
              <div className="info">
                <div className="info-item">
                  <span className="info-label">Benefit Received : </span>
                  <span>{scheme.BR}</span>
                </div>
                <div className="info-item">
                  <span className="info-label1">Application Opened : </span>
                  <span>{scheme.OpenCount}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default RightSidebar;
