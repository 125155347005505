import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './HistMap.css'; // You can reuse the same CSS file
import L from 'leaflet';

const HistMap = () => {
  const [geoData, setGeoData] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  useEffect(() => {
    fetch('/IndiaMap1.json') // Make sure to replace with your historical GeoJSON file path
      .then(response => response.json())
      .then(data => {
        console.log('GeoJSON data loaded:', data);
        setGeoData(data);
      })
      .catch(error => console.error('Error loading the GeoJSON data:', error));
  }, []);

  const onEachFeature = (feature, layer) => {
    // Define pop-up content
    const popupContent = `
      <b>${feature.properties.NAME_1}</b><br/>
      <!-- Add more information if available -->
      
    `;

    // Bind pop-up to layer
    layer.bindPopup(popupContent);

    // Add click event to update selected state
    layer.on({
      click: () => {
        console.log('Clicked on:', feature.properties.NAME_1);
        setSelectedState(feature.properties.NAME_1);
      }
    });
  };

  const styleFeature = (feature) => {
    return {
      fillColor: feature.properties.NAME_1 === selectedState ? '#ff7800' : '#CF9FFF',
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7
    };
  };

  return (
    <div className="histmap">
      <MapContainer center={[22.9734, 78.6569]} zoom={5} style={{ height: '100%', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {geoData && (
          <GeoJSON
            data={geoData}
            onEachFeature={onEachFeature}
            style={styleFeature}
          />
        )}
      </MapContainer>
      {selectedState && <div className="selected-state-info">Selected State: {selectedState}</div>}
    </div>
  );
};

export default HistMap;
